<template>
  <v-dialog v-model="value" persistent max-width="600px">
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-title class="bg-gradient-primary-2">
          <span class="white--text">{{
            editItem ? 'Edit Transaction Rule' : 'Add New Entry'
          }}</span>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="6">
              <ac-input
                v-model="type"
                label="Type"
                outlined
                :validation-rules="[validator.required]"
              ></ac-input>
            </v-col>
            <v-col cols="6">
              <ac-date-picker
                v-model="effectiveDate"
                label="Effactive Date:"
                outlined
                :validation-rules="[validator.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <ac-select
                label="Status"
                v-model="userStatus"
                :items="[
                  { id: 'Active', name: 'Active' },
                  { id: 'Non-Active', name: 'Not Active' },
                ]"
                class="company-select"
              />
            </v-col>
            <v-col cols="6">
              <ac-input v-model="summary" label="Summary" outlined></ac-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ac-button
                title="Upload Transaction"
                :icon="icons.mdiUpload"
                @click="showUpload = true"
              ></ac-button>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <ac-button title="Cancel" color="error" outlined @click="onClose" />
          <ac-button title="Submit" color="success" :loading="loading" @click="onSubmit" />
          <upload-modal v-model="showUpload" @custom-event="onUpload" title="approval" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { required } from '@core/utils/validation'
import { mdiUpload } from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'UpsertTransactionRuleModal',

  components: {
    AcInput: () => import('@/components/AcInput'),
    AcButton: () => import('@/components/AcButton'),
    AcSelect: () => import('@/components/AcSelect'),
    AcDatePicker: () => import('@/components/AcDatePicker'),
    AcIconBtn: () => import('@/components/AcIconBtn'),
    UploadModal: () => import('@/components/modals/UploadModal'),
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    editItem: {
      type: Object,
    },
  },

  data() {
    return {
      valid: false,
      loading: false,
      licenseTypes: [],
      showUpload: false,
      valid: false,
      type: null,
      summary: null,
      title: null,
      effectiveDate: null,
      userStatus: null,
      validator: {
        required,
      },
      icons: {
        mdiUpload,
      },
    }
  },
  computed: {
    ...mapGetters('app', ['selectedCompany']),
    ...mapGetters('transactionRules', ['getUrl']),
  },
  methods: {
    ...mapActions('transactionRules', [
      'createTransactionRule',
      'fetchTransactions',
      'updateTransactionRule',
      'uploadFile',
    ]),

    async onSubmit() {
      this.$refs.form.validate()

      if (this.valid) {
        this.loading = true

        if (this.editItem) {
          const payload = {
            type: this.type,
            summary: this.summary,
            effectiveDate: this.effectiveDate,
            status: this.userStatus,
            companyId: this.selectedCompany,
            date: '',
            _id: this.editItem.id,
            logs: this.editItem.logs,
            createdAt: this.editItem.createdAt,
            attachment: this.getUrl,
          }

          const { success, message } = await this.updateTransactionRule(payload)

          if (success) {
            await this.fetchTransactions(this.selectedCompany)
          }

          const notificationType = success ? 'success' : 'error'
          const position = 'bottom'

          this.AcNotify({
            type: notificationType,
            message,
            position,
          })

          this.onClose()
        } else {
          const payload = {
            type: this.type,
            summary: this.summary,
            effectiveDate: this.effectiveDate,
            status: this.userStatus,
            companyId: this.selectedCompany,
            date: '',
            attachment: this.getUrl,
          }

          const { success, message } = await this.createTransactionRule(payload)

          if (success) {
            await this.fetchTransactions(this.selectedCompany)
          }

          const notificationType = success ? 'success' : 'error'
          const position = 'bottom'

          this.AcNotify({
            type: notificationType,
            message,
            position,
          })

          this.onClose()
        }
        this.loading = false
      }
    },
    onClose(refetch) {
      this.userStatus = null
      this.type = null
      this.summary = null
      this.effectiveDate = null

      if (refetch) {
        const { selectedCompany } = this
        this.$emit('onClose', { selectedCompany })
      }

      this.reset()
      this.$emit('input', false)
    },

    async onUpload(fileData) {
      this.uploadFile(fileData)
      this.showUpload = false
    },

    reset() {
      this.licenses = []
      this.$refs.form.reset()
    },
  },
  watch: {
    immediate: true,
    value: {
      handler(val) {
        if (val && this.editItem) {
          const { type, summary, effectiveDate, status } = this.editItem
          this.userStatus = status
          this.type = type
          this.summary = summary
          this.effectiveDate = effectiveDate
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped></style>

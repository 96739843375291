import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,[_c(VCardTitle,{staticClass:"bg-gradient-primary-2"},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.editItem ? 'Edit Transaction Rule' : 'Add New Entry'))])]),_c(VCardText,{staticClass:"pt-4"},[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c('ac-input',{attrs:{"label":"Type","outlined":"","validation-rules":[_vm.validator.required]},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c('ac-date-picker',{attrs:{"label":"Effactive Date:","outlined":"","validation-rules":[_vm.validator.required]},model:{value:(_vm.effectiveDate),callback:function ($$v) {_vm.effectiveDate=$$v},expression:"effectiveDate"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c('ac-select',{staticClass:"company-select",attrs:{"label":"Status","items":[
                { id: 'Active', name: 'Active' },
                { id: 'Non-Active', name: 'Not Active' } ]},model:{value:(_vm.userStatus),callback:function ($$v) {_vm.userStatus=$$v},expression:"userStatus"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c('ac-input',{attrs:{"label":"Summary","outlined":""},model:{value:(_vm.summary),callback:function ($$v) {_vm.summary=$$v},expression:"summary"}})],1)],1),_c(VRow,[_c(VCol,[_c('ac-button',{attrs:{"title":"Upload Transaction","icon":_vm.icons.mdiUpload},on:{"click":function($event){_vm.showUpload = true}}})],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('ac-button',{attrs:{"title":"Cancel","color":"error","outlined":""},on:{"click":_vm.onClose}}),_c('ac-button',{attrs:{"title":"Submit","color":"success","loading":_vm.loading},on:{"click":_vm.onSubmit}}),_c('upload-modal',{attrs:{"title":"approval"},on:{"custom-event":_vm.onUpload},model:{value:(_vm.showUpload),callback:function ($$v) {_vm.showUpload=$$v},expression:"showUpload"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }